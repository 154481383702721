import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, Container, Grid, Card, CardContent, CardMedia, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { createTheme } from '@mui/material/styles';
import '@fontsource/poppins/600.css';
import '@fontsource/open-sans/400.css';
import Footer from '../components/Footer';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

// Use the same theme as your other pages
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Open Sans, sans-serif',
    h1: { fontFamily: 'Poppins, sans-serif', fontWeight: 700 },
    h2: { fontFamily: 'Poppins, sans-serif', fontWeight: 600 },
    h3: { fontFamily: 'Poppins, sans-serif', fontWeight: 600 },
    body1: { fontFamily: 'Open Sans, sans-serif' },
  },
  palette: {
    primary: { main: '#1A4E7A' }, // Deep blue
    secondary: { main: '#F28C38' }, // Burnt orange
  },
});

function WhatsInYourWater() {
  const canvasRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  // Add scroll effect handler
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Particle animation from HomePage for hero consistency
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = 250;
    };
    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    const particles = Array.from({ length: 20 }, () => ({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      radius: Math.random() * 3 + 2,
      dx: (Math.random() - 0.5) * 0.4,
      dy: (Math.random() - 0.5) * 0.4,
    }));

    const drawParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
      particles.forEach(particle => {
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
        ctx.fill();
        particle.x += particle.dx;
        particle.y += particle.dy;
        if (particle.x < 0 || particle.x > canvas.width) particle.dx = -particle.dx;
        if (particle.y < 0 || particle.y > canvas.height) particle.dy = -particle.dy;
      });
      animationFrameId = requestAnimationFrame(drawParticles);
    };
    drawParticles();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  const handleOrderTestKit = () => window.open('https://mytapscore.com/collections/city-water-test-kits', '_blank');
  const handleSearchZipcode = () => window.open('https://www.ewg.org/tapwater/', '_blank');

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ minHeight: '100vh', background: '#f4f7fa', overflowX: 'hidden' }}>
        {/* Replace existing header with new styled header */}
        <Box sx={{
          position: isScrolled ? 'fixed' : 'absolute',
          left: 0,
          right: 0,
          top: 0,
          zIndex: 1000,
          background: isScrolled ? 'rgba(244, 247, 250, 0.7)' : 'transparent',
          padding: '20px 30px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backdropFilter: isScrolled ? 'blur(5px)' : 'none',
          boxShadow: isScrolled ? '0 2px 10px rgba(0,0,0,0.1)' : 'none',
          transition: 'all 0.3s ease',
          boxSizing: 'border-box'
        }}>
          <a href="/" style={{ textDecoration: 'none' }}>
            <img
              src="/MWPlogo.png"
              alt="Marin Water Pros Logo"
              style={{ 
                height: isScrolled ? '60px' : '80px', 
                objectFit: 'contain', 
                filter: 'drop-shadow(0 2px 5px rgba(0,0,0,0.3))',
                transition: 'height 0.3s ease'
              }}
            />
          </a>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<RequestQuoteIcon />}
            href="/get-a-quote"
            sx={{
              borderRadius: '20px',
              textTransform: 'none',
              fontSize: '1rem',
              borderColor: '#F28C38',
              color: '#F28C38',
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: 'rgba(242, 140, 56, 0.1)',
                borderColor: '#F28C38',
                color: '#F28C38',
              },
            }}
          >
            Get a Quote
          </Button>
        </Box>

        {/* Update hero section padding to account for header */}
        <Box sx={{
          position: 'relative',
          background: 'linear-gradient(135deg, #1A4E7A 0%, #2A6A8F 100%)',
          padding: isScrolled ? '160px 20px 60px' : '120px 20px 60px',
          textAlign: 'center',
          color: '#fff',
          transition: 'padding 0.3s ease',
        }}>
          <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', mixBlendMode: 'overlay' }} />
          <Container maxWidth="md">
            <Typography variant="h2" sx={{ fontSize: 'clamp(2.5rem, 5vw, 4rem)', mb: 2, textShadow: '0 2px 10px rgba(0,0,0,0.3)' }}>
              What's in Your Water?
            </Typography>
            <Typography variant="h5" sx={{ fontSize: 'clamp(1.1rem, 2.5vw, 1.5rem)', opacity: 0.9 }}>
              Discover the quality of your tap water with these trusted tools.
            </Typography>
          </Container>
        </Box>

        {/* Main Content */}
        <Container sx={{ padding: '60px 20px' }}>
          <Grid container spacing={4}>
            {/* City Water Test Kits */}
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%', borderRadius: '12px', boxShadow: '0 5px 20px rgba(0,0,0,0.1)' }}>
                <CardMedia
                  component="img"
                  image="/tapscore.png"
                  alt="City Water Test Kit"
                  sx={{ height: 200, objectFit: 'cover', borderRadius: '12px 12px 0 0' }}
                />
                <CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                  <Typography variant="h4" sx={{ color: '#1A4E7A', fontSize: 'clamp(1.5rem, 3vw, 2rem)', mb: 2 }}>
                    City Water Test Kits
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#555', mb: 2, fontSize: '1.1rem' }}>
                    Recommended for basic water quality testing in homes served by a water utility.
                  </Typography>
                  <List dense>
                    {['Certified lab testing', 'Fast turnaround (5 days)', 'Free shipping both ways', 'Expert support'].map((feature, index) => (
                      <ListItem key={index} sx={{ py: 0.5 }}>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon color="primary" sx={{ fontSize: '1.2rem' }} />
                        </ListItemIcon>
                        <ListItemText primary={feature} primaryTypographyProps={{ fontSize: '1.1rem', color: '#555' }} />
                      </ListItem>
                    ))}
                  </List>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={handleOrderTestKit}
                    sx={{ mt: 'auto', padding: '10px 25px', borderRadius: '8px', alignSelf: 'center' }}
                  >
                    Order a Test Kit
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            {/* EWG Tap Water Database */}
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%', borderRadius: '12px', boxShadow: '0 5px 20px rgba(0,0,0,0.1)' }}>
                <CardMedia
                  component="img"
                  image="/zipcode.png"
                  alt="EWG Tap Water Database"
                  sx={{ height: 200, objectFit: 'cover', borderRadius: '12px 12px 0 0' }}
                />
                <CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                  <Typography variant="h4" sx={{ color: '#1A4E7A', fontSize: 'clamp(1.5rem, 3vw, 2rem)', mb: 2 }}>
                    EWG Tap Water Database
                  </Typography>
                  <Typography variant="h6" sx={{ color: '#1A4E7A', fontSize: '1.25rem', mb: 1 }}>
                    Know your environment. Protect your health.
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#555', mb: 2, fontSize: '1.1rem' }}>
                    Check your water utility's latest test results and compliance with federal standards via EWG's database.
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={handleSearchZipcode}
                    sx={{ mt: 'auto', padding: '10px 25px', borderRadius: '8px', alignSelf: 'center' }}
                  >
                    Search Your Zip Code
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>

        {/* Replace existing footer with Footer component */}
        <Footer />
      </Box>

      {/* Add animation styles */}
      <style>
        {`
          @keyframes fadeInUp {
            from { opacity: 0; transform: translateY(20px); }
            to { opacity: 1; transform: translateY(0); }
          }
        `}
      </style>
    </ThemeProvider>
  );
}

export default WhatsInYourWater;