import React, { useState } from 'react';
import {
  Box, Container, Grid, Typography, Button, Divider, Modal, Paper, IconButton,
  List, ListItem, ListItemText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VerifiedIcon from '@mui/icons-material/Verified';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 600,
  maxHeight: '90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflow: 'auto'
};

const Footer = () => {
  const [openModal, setOpenModal] = useState(null);

  const handleOpen = (modalName) => setOpenModal(modalName);
  const handleClose = () => setOpenModal(null);

  const modalContent = {
    about: {
      title: "About Marin Water Pros",
      content: (
        <>
          <Typography paragraph>
            Marin Water Pros is your trusted partner in water filtration solutions. We specialize in providing high-quality water filtration systems for homes and businesses throughout Marin County.
          </Typography>
          <Typography paragraph>
            Our team of certified professionals brings years of experience in water filtration installation and maintenance. We're committed to delivering clean, safe water through expert service and cutting-edge filtration technology.
          </Typography>
          <Box sx={{ mt: 3, p: 2, bgcolor: '#f5f5f5', borderRadius: 1 }}>
            <Typography variant="h6" gutterBottom color="primary">Our Values</Typography>
            <List>
              <ListItem>
                <ListItemText 
                  primary="Quality" 
                  secondary="We never compromise on the quality of our products or services"
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Integrity" 
                  secondary="Honest pricing and transparent communication"
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Customer Focus" 
                  secondary="Your satisfaction is our top priority"
                />
              </ListItem>
            </List>
          </Box>
        </>
      )
    },
    privacy: {
      title: "Privacy Policy",
      content: (
        <>
          <Typography paragraph>
            Last updated: {new Date().toLocaleDateString()}
          </Typography>
          <Typography paragraph>
            At Marin Water Pros, we take your privacy seriously. This privacy policy describes how we collect, use, and protect your personal information when you use our website or services.
          </Typography>
          <Typography variant="h6" gutterBottom>Information We Collect</Typography>
          <Typography paragraph>
            We collect information that you provide directly to us, including:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Contact information (name, email, phone number)" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Address and location information" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Service preferences and requirements" />
            </ListItem>
          </List>
          <Typography variant="h6" gutterBottom>How We Use Your Information</Typography>
          <Typography paragraph>
            We use the information we collect to provide and improve our services, communicate with you, and process your service requests.
          </Typography>
        </>
      )
    },
    terms: {
      title: "Terms of Service",
      content: (
        <>
          <Typography paragraph>
            These terms of service outline the rules and regulations for using Marin Water Pros' services.
          </Typography>
          <Typography variant="h6" gutterBottom>Service Terms</Typography>
          <List>
            <ListItem>
              <ListItemText 
                primary="Installation Warranty" 
                secondary="All installations come with a 1-year warranty on workmanship"
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Scheduling" 
                secondary="24-hour notice required for appointment cancellation"
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Payment" 
                secondary="Payment is due upon completion of installation"
              />
            </ListItem>
          </List>
        </>
      )
    }
  };

  return (
    <Box sx={{ bgcolor: '#1A4E7A', color: 'white', pt: 6, pb: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" sx={{ mb: 2 }}>Marin Water Pros</Typography>
            <Typography variant="body2" sx={{ opacity: 0.9, mb: 2 }}>
              Expert water filtration solutions for your home or business.
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <VerifiedIcon sx={{ mr: 1, fontSize: '1rem' }} />
              <Typography variant="body2">Licensed & Insured</Typography>
            </Box>
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" sx={{ mb: 2 }}>Quick Links</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Button 
                color="inherit" 
                onClick={() => handleOpen('about')}
                sx={{ justifyContent: 'center' }}
              >
                About Us
              </Button>
              <Button 
                color="inherit" 
                onClick={() => handleOpen('privacy')}
                sx={{ justifyContent: 'center' }}
              >
                Privacy Policy
              </Button>
              <Button 
                color="inherit" 
                onClick={() => handleOpen('terms')}
                sx={{ justifyContent: 'center' }}
              >
                Terms of Service
              </Button>
            </Box>
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" sx={{ mb: 2 }}>Contact Us</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LocalPhoneIcon sx={{ mr: 1, fontSize: '1.2rem' }} />
                <Typography variant="body2" component="a" href="tel:(123) 456-7890" sx={{ 
                  color: 'white', 
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline' }
                }}>
                  (123) 456-7890
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <EmailIcon sx={{ mr: 1, fontSize: '1.2rem' }} />
                <Typography variant="body2" component="a" href="mailto:info@marinwaterpros.com" sx={{ 
                  color: 'white', 
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline' }
                }}>
                  info@marinwaterpros.com
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LocationOnIcon sx={{ mr: 1, fontSize: '1.2rem' }} />
                <Typography variant="body2">
                  Marin County, CA
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        
        <Divider sx={{ my: 3, backgroundColor: 'rgba(255,255,255,0.3)' }} />
        
        <Typography variant="body2" sx={{ opacity: 0.8, textAlign: 'center' }}>
          © {new Date().getFullYear()} Marin Water Pros. All rights reserved.
        </Typography>
      </Container>

      {/* Modals */}
      <Modal
        open={!!openModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
      >
        <Paper sx={modalStyle}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'grey.500'
            }}
          >
            <CloseIcon />
          </IconButton>
          
          <Typography id="modal-title" variant="h4" component="h2" gutterBottom color="primary">
            {openModal && modalContent[openModal].title}
          </Typography>
          
          {openModal && modalContent[openModal].content}
        </Paper>
      </Modal>
    </Box>
  );
};

export default Footer;