import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, Container, Grid, Card, CardContent, CardMedia, Dialog, Chip, Tabs, Tab, Paper } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import BuildIcon from '@mui/icons-material/Build';
import ScienceIcon from '@mui/icons-material/Science';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import '@fontsource/roboto/700.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/poppins/600.css';
import Footer from '../components/Footer';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Open Sans, sans-serif',
    h1: { fontFamily: 'Poppins, sans-serif', fontWeight: 700 },
    h2: { fontFamily: 'Poppins, sans-serif', fontWeight: 600 },
    h3: { fontFamily: 'Poppins, sans-serif', fontWeight: 600 },
    body1: { fontFamily: 'Open Sans, sans-serif' },
  },
  palette: {
    primary: { main: '#1A4E7A' }, // Deep blue
    secondary: { main: '#F28C38' }, // Burnt orange, plumbing-friendly
  },
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: { xs: 2, md: 3 } }}>{children}</Box>}
    </div>
  );
};

function HomePage() {
  const canvasRef = useRef(null);
  const [openVideo, setOpenVideo] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const isMobile = window.innerWidth < 768;
    const particleCount = isMobile ? 15 : 30;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight < 768 ? 250 : 350;
    };
    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    const particles = [];
    for (let i = 0; i < particleCount; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 3 + 2,
        dx: (Math.random() - 0.5) * 0.4,
        dy: (Math.random() - 0.5) * 0.4,
      });
    }

    const drawParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
      particles.forEach(particle => {
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
        ctx.fill();
        particle.x += particle.dx;
        particle.y += particle.dy;
        if (particle.x < 0 || particle.x > canvas.width) particle.dx = -particle.dx;
        if (particle.y < 0 || particle.y > canvas.height) particle.dy = -particle.dy;
      });
      animationFrameId = requestAnimationFrame(drawParticles);
    };
    drawParticles();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const videos = [
    { id: '4FRy2bWjGMw', title: 'Understanding Water Quality', subtext: 'Learn about the importance of clean water', thumbnail: 'https://img.youtube.com/vi/4FRy2bWjGMw/maxresdefault.jpg' },
    { id: 'T-OC980OXIg', title: 'Water Treatment Process', subtext: 'Discover how we purify your water', thumbnail: 'https://img.youtube.com/vi/T-OC980OXIg/maxresdefault.jpg' },
    { id: 'IJ_CvgT47bI', title: 'Benefits of Filtered Water', subtext: 'Why filtered water is essential for your health', thumbnail: 'https://img.youtube.com/vi/IJ_CvgT47bI/maxresdefault.jpg' },
  ];

  const handleVideoClick = (videoId) => setOpenVideo(videoId);
  const handleClose = () => setOpenVideo(null);
  const handleTabChange = (event, newValue) => setTabValue(newValue);

  return (
    <ThemeProvider theme={theme}>
      <div style={{ 
        minHeight: '100vh', 
        fontFamily: 'Poppins, sans-serif', 
        background: '#f4f7fa',
        overflowX: 'hidden',  // Prevent horizontal scrolling
        width: '100%'
      }}>
        {/* Update Header styles */}
        <div style={{
          position: isScrolled ? 'fixed' : 'absolute',
          left: 0,
          right: 0,
          top: 0,
          zIndex: 1000,
          background: isScrolled ? 'rgba(244, 247, 250, 0.7)' : 'transparent',
          padding: '20px 30px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backdropFilter: isScrolled ? 'blur(5px)' : 'none',
          boxShadow: isScrolled ? '0 2px 10px rgba(0,0,0,0.1)' : 'none',
          transition: 'all 0.3s ease',
          boxSizing: 'border-box'
        }}>
          <img
            src="/MWPlogo.png"
            alt="Marin Water Pros Logo"
            style={{ 
              height: isScrolled ? '60px' : '80px', 
              objectFit: 'contain', 
              filter: 'drop-shadow(0 2px 5px rgba(0,0,0,0.3))',
              transition: 'height 0.3s ease'
            }}
          />
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Button
              variant="text"
              href="/whats-in-your-water"
              sx={{
                color: isScrolled ? '#1A4E7A' : 'white',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 500,
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                transition: 'color 0.3s ease',
              }}
            >
              What's in your water?
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<RequestQuoteIcon />}
              href="/get-a-quote"
              sx={{
                borderRadius: '20px',
                textTransform: 'none',
                fontSize: '1rem',
                borderColor: '#F28C38',
                color: '#F28C38',
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(242, 140, 56, 0.1)',
                  borderColor: '#F28C38',
                  color: '#F28C38',
                },
              }}
            >
              Get a Quote
            </Button>
          </Box>
        </div>

        {/* Update Hero section */}
        <div style={{
          position: 'relative',
          background: 'linear-gradient(135deg, #1A4E7A 0%, #2A6A8F 100%)',
          padding: isScrolled ? '160px 20px 80px' : '120px 20px 80px',
          overflow: 'hidden',
          transition: 'padding 0.3s ease',
        }}>
          <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', mixBlendMode: 'overlay' }} />
          <Container style={{ position: 'relative', textAlign: 'center', color: '#fff', maxWidth: '900px' }}>
            <Typography
              variant="h2"
              style={{
                fontSize: 'clamp(2.5rem, 5vw, 4rem)',
                marginBottom: '20px',
                textShadow: '0 2px 10px rgba(0,0,0,0.3)',
                animation: 'fadeInUp 1s ease-out',
              }}
            >
              Filtration without Frustration
            </Typography>
            <Typography
              variant="h5"
              style={{
                marginBottom: '30px',
                fontSize: 'clamp(1.1rem, 2.5vw, 1.5rem)',
                opacity: 0.9,
                animation: 'fadeInUp 1s ease-out 0.2s',
                animationFillMode: 'both',
              }}
            >
              Experts in At-Home Reverse Osmosis • 100% Satisfaction
            </Typography>
          </Container>
        </div>

        {/* Why Filter Your Water */}
        <Container style={{ padding: '80px 20px', textAlign: 'center' }}>
          <Typography
            variant="h3"
            style={{
              fontSize: 'clamp(1.8rem, 4vw, 2.5rem)',
              marginBottom: '20px',
              color: '#1A4E7A',
              textShadow: '0 1px 5px rgba(0,0,0,0.1)',
            }}
          >
            Why Filter Your Water?
          </Typography>
          <Typography style={{ marginBottom: '40px', color: '#555', fontSize: 'clamp(1rem, 2vw, 1.2rem)' }}>
            Discover the benefits of clean, filtered water for you and your family
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '30px' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="water filtration benefits" centered>
              <Tab icon={<HealthAndSafetyIcon />} label="Health" sx={{ fontSize: '1.1rem', padding: '10px 20px' }} />
              <Tab icon={<WaterDropIcon />} label="Quality" sx={{ fontSize: '1.1rem', padding: '10px 20px' }} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={5} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
                <CardMedia
                  component="img"
                  sx={{
                    width: '100%',
                    maxWidth: 450,
                    height: 'auto',
                    borderRadius: '12px',
                    boxShadow: '0 5px 15px rgba(0,0,0,0.2)'
                  }}
                  image="/waterfamily.png"
                  alt="Health benefits"
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography variant="h4" gutterBottom sx={{ color: '#1A4E7A', fontWeight: 600, fontSize: 'clamp(1.5rem, 3vw, 2rem)' }}>
                  Health & Safety Benefits
                </Typography>
                <Typography style={{ fontSize: '1.1rem', marginBottom: '20px', color: '#555' }}>
                  Protect your family's health by reducing exposure to harmful contaminants like lead, bacteria, and chlorine.
                </Typography>
                <ul style={{ listStyle: 'none', padding: 0, color: '#555' }}>
                  <li style={{ fontSize: '1.1rem', marginBottom: '10px' }}><WaterDropIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#1A4E7A' }} /> Reduced exposure to contaminants</li>
                  <li style={{ fontSize: '1.1rem', marginBottom: '10px' }}><WaterDropIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#1A4E7A' }} /> Improved hydration</li>
                  <li style={{ fontSize: '1.1rem' }}><WaterDropIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#1A4E7A' }} /> Healthier skin and hair</li>
                </ul>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={5} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
                <CardMedia
                  component="img"
                  sx={{
                    width: '100%',
                    maxWidth: 450,
                    height: 'auto',
                    borderRadius: '12px',
                    boxShadow: '0 5px 15px rgba(0,0,0,0.2)'
                  }}
                  image="/faucetwater.png"
                  alt="Water quality"
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography variant="h4" gutterBottom sx={{ color: '#1A4E7A', fontWeight: 600, fontSize: 'clamp(1.5rem, 3vw, 2rem)' }}>
                  Superior Water Quality
                </Typography>
                <Typography style={{ fontSize: '1.1rem', marginBottom: '20px', color: '#555' }}>
                  Our systems remove up to 99% of contaminants, ensuring crystal-clear water for drinking and cooking.
                </Typography>
                <ul style={{ listStyle: 'none', padding: 0, color: '#555' }}>
                  <li style={{ fontSize: '1.1rem', marginBottom: '10px' }}><WaterDropIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#1A4E7A' }} /> Enjoy crisp, clean-tasting water</li>
                  <li style={{ fontSize: '1.1rem', marginBottom: '10px' }}><WaterDropIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#1A4E7A' }} /> Eliminate chlorine smells</li>
                  <li style={{ fontSize: '1.1rem' }}><WaterDropIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#1A4E7A' }} /> Crystal-clear water</li>
                </ul>
              </Grid>
            </Grid>
          </TabPanel>
        </Container>

        {/* Services */}
        <Container style={{ padding: '80px 20px', textAlign: 'center', background: '#fff' }}>
          <Typography variant="h3" style={{ fontSize: 'clamp(1.8rem, 4vw, 2.5rem)', marginBottom: '20px', color: '#1A4E7A' }}>
            Our Specialization
          </Typography>
          <Typography 
            style={{ 
              marginBottom: '40px', 
              color: '#555', 
              fontSize: 'clamp(1rem, 2vw, 1.2rem)',
              maxWidth: '800px',
              margin: '0 auto 60px'
            }}
          >
            We specialize in under-countertop filtration, providing comprehensive solutions for your water needs
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 3,
              overflowX: 'auto',
              pb: 2, // Add padding bottom for scrollbar
              mx: { xs: -2, md: 0 }, // Negative margin on mobile to allow full-width scrolling
              px: { xs: 2, md: 0 }, // Padding to offset negative margin
              '::-webkit-scrollbar': {
                height: '6px',
              },
              '::-webkit-scrollbar-track': {
                background: '#f1f1f1',
                borderRadius: '10px',
              },
              '::-webkit-scrollbar-thumb': {
                background: '#1A4E7A',
                borderRadius: '10px',
              },
              scrollSnapType: 'x mandatory',
              '& > *': {
                scrollSnapAlign: 'start',
              },
            }}
          >
            {[
              { 
                name: 'RO Consulting',
                text: 'Expert guidance on the perfect filtration solution for your home',
                icon: <BuildIcon sx={{ fontSize: 40 }}/>,
                highlight: 'Free Consultation'
              },
              { 
                name: 'Water Testing',
                text: 'Professional analysis of your water quality and contamination levels',
                icon: <ScienceIcon sx={{ fontSize: 40 }}/>,
                highlight: 'Lab Certified'
              },
              { 
                name: 'RO Installation',
                text: 'Swift, professional installation by certified technicians',
                icon: <PlumbingIcon sx={{ fontSize: 40 }}/>,
                highlight: '2-Hour Install'
              },
            ].map((item, index) => (
              <Paper
                key={index}
                elevation={0}
                sx={{
                  p: 3, // Reduced padding
                  minWidth: { xs: '280px', md: '300px' }, // Fixed width for carousel
                  maxWidth: { xs: '280px', md: '300px' },
                  borderRadius: '16px',
                  position: 'relative',
                  overflow: 'hidden',
                  transition: 'all 0.3s ease',
                  border: '1px solid rgba(26, 78, 122, 0.1)',
                  backgroundColor: 'white',
                  flex: { xs: '0 0 auto', md: '1 1 0' }, // Don't grow on mobile
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 10px 30px rgba(26, 78, 122, 0.15)',
                    '& .icon-container': {
                      transform: 'scale(1.1)',
                      backgroundColor: '#1A4E7A',
                      '& svg': {
                        color: 'white',
                      }
                    },
                    '& .highlight': {
                      backgroundColor: '#F28C38',
                      color: 'white',
                    }
                  },
                }}
              >
                <Box
                  className="icon-container"
                  sx={{
                    width: '60px', // Reduced size
                    height: '60px', // Reduced size
                    borderRadius: '50%',
                    backgroundColor: 'rgba(26, 78, 122, 0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '0 auto 16px', // Reduced margin
                    transition: 'all 0.3s ease',
                    '& svg': {
                      color: '#1A4E7A',
                      transition: 'all 0.3s ease',
                      fontSize: '30px', // Reduced icon size
                    }
                  }}
                >
                  {item.icon}
                </Box>
                <Typography
                  className="highlight"
                  sx={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    fontSize: '0.85rem',
                    padding: '4px 12px',
                    borderRadius: '12px',
                    backgroundColor: 'rgba(242, 140, 56, 0.1)',
                    color: '#F28C38',
                    transition: 'all 0.3s ease',
                  }}
                >
                  {item.highlight}
                </Typography>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    mb: 1, // Reduced margin
                    color: '#1A4E7A',
                    fontWeight: 600,
                    fontSize: '1.2rem' // Reduced font size
                  }}
                >
                  {item.name}
                </Typography>
                <Typography
                  sx={{
                    color: '#666',
                    fontSize: '0.95rem', // Reduced font size
                    lineHeight: 1.5
                  }}
                >
                  {item.text}
                </Typography>
              </Paper>
            ))}
          </Box>
        </Container>

        {/* Video Section */}
        <Container style={{ padding: '80px 20px', background: 'linear-gradient(180deg, #f5f5f5, #d9e0e5)' }}>
          <Typography variant="h3" style={{ fontSize: 'clamp(1.8rem, 4vw, 2.5rem)', marginBottom: '20px', textAlign: 'center', color: '#1A4E7A' }}>
            Don't Just Take It From Us
          </Typography>
          <Typography style={{ textAlign: 'center', marginBottom: '40px', color: '#555', fontSize: 'clamp(1rem, 2vw, 1.2rem)' }}>
            Health experts agree: filtering your water is a critical investment.
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {videos.map((video, index) => (
              <Grid item xs={12} sm={6} md={4} key={video.id}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '12px',
                    overflow: 'hidden',
                    boxShadow: '0 5px 20px rgba(0,0,0,0.1)',
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 10px 30px rgba(26, 78, 122, 0.3)',
                      border: '1px solid #1A4E7A',
                    },
                  }}
                  onClick={() => handleVideoClick(video.id)}
                >
                  <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
                    <img
                      src={video.thumbnail}
                      alt={video.title}
                      style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                    <PlayArrowIcon
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: 'clamp(50px, 10vw, 70px)',
                        color: 'white',
                        opacity: 0.85,
                        transition: 'opacity 0.3s',
                        '&:hover': { opacity: 1 },
                      }}
                    />
                  </Box>
                  <CardContent sx={{ flexGrow: 1, textAlign: 'center', padding: '20px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant="h5" sx={{ fontWeight: 600, marginBottom: '10px', color: '#1A4E7A', fontSize: '1.2rem' }}>{video.title}</Typography>
                      {index === 0 && <Chip icon={<LocalFireDepartmentIcon />} label="Most Watched" color="secondary" sx={{ mb: 1, fontSize: '0.9rem' }} />}
                    </Box>
                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.95rem' }}>{video.subtext}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Dialog open={!!openVideo} onClose={handleClose} maxWidth="md" fullWidth>
            {openVideo && (
              <iframe
                width="100%"
                height="clamp(300px, 50vh, 500px)"
                src={`https://www.youtube.com/embed/${openVideo}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </Dialog>
        </Container>

        {/* Contact */}
        <div id="contact" style={{ 
          position: 'relative', 
          background: '#fff',
          padding: '60px 20px', 
          textAlign: 'center',
          color: '#1A4E7A'
        }}>
          <Container style={{ maxWidth: '900px', position: 'relative' }}>
            <Typography 
              variant="h3" 
              style={{ 
                fontSize: 'clamp(1.8rem, 4vw, 2.5rem)', 
                marginBottom: '20px', 
                fontWeight: 600,
                color: '#1A4E7A'
              }}
            >
              Ready to say goodbye to tap water?
            </Typography>
            <Typography 
              style={{ 
                fontSize: 'clamp(1rem, 2vw, 1.2rem)', 
                opacity: 0.9,
                maxWidth: '700px',
                margin: '0 auto 30px',
                color: '#555'
              }}
            >
              Let Marin Water Pros get your filter system set up today!
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              href="/get-a-quote"
              startIcon={<RequestQuoteIcon />}
              sx={{
                fontSize: '1.1rem',
                padding: '12px 30px',
                borderRadius: '8px',
                backgroundColor: '#F28C38',
                '&:hover': {
                  backgroundColor: '#E07B30',
                },
                textTransform: 'none',
                boxShadow: '0 3px 10px rgba(242, 140, 56, 0.3)',
              }}
            >
              Get Started
            </Button>
          </Container>
        </div>

        <Footer />
      </div>

      {/* Inline CSS Animations */}
      <style>
        {`
          @keyframes fadeInUp {
            from { opacity: 0; transform: translateY(20px); }
            to { opacity: 1; transform: translateY(0); }
          }
          @media (max-width: 768px) {
            .MuiTabs-root { flex-direction: 'column'; }
            .MuiTab-root { width: 100%; }
          }
          @media (max-width: 600px) {
            iframe { height: 300px !important; }
          }
        `}
      </style>
    </ThemeProvider>
  );
}

export default HomePage;