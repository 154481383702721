import React from 'react';
import { useLocation } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Paper, 
  List, 
  ListItem, 
  useTheme,
  Button,
  Container
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Footer from '../components/Footer';
import { useState, useEffect } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HomeIcon from '@mui/icons-material/Home';

function ThankYou() {
  const theme = useTheme();
  const location = useLocation();
  const { formData, formType } = location.state || {};
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const renderQuoteSummary = () => (
    <>
      <Typography variant="h4" gutterBottom>
        Thank You!
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        We've received your quote request and will contact you shortly.
      </Typography>

      <Paper elevation={1} sx={{ p: 3, backgroundColor: '#f8f8f8', textAlign: 'left' }}>
        <Typography variant="h6" gutterBottom color="primary">
          Your Quote Summary
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body1">
              <strong>Package:</strong> {formData.packageName}
            </Typography>
          </ListItem>
          {formData.addons && (
            <ListItem>
              <Typography variant="body1">
                <strong>Add-ons:</strong> {formData.addons || 'None'}
              </Typography>
            </ListItem>
          )}
          <ListItem>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 2
            }}>
              <Typography variant="body1">
                <strong>Total Estimate:</strong>
              </Typography>
              {formData.promoApplied === 'Yes (5% off)' ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography 
                    variant="body1" 
                    sx={{ textDecoration: 'line-through', color: 'text.secondary' }}
                  >
                    ${Number(formData.subtotal).toFixed(2)}
                  </Typography>
                  <Typography 
                    variant="body1" 
                    color="success.main"
                    sx={{ fontWeight: 'bold' }}
                  >
                    ${Number(formData.total).toFixed(2)}
                    <Typography 
                      component="span" 
                      variant="body2" 
                      color="success.main" 
                      sx={{ ml: 1 }}
                    >
                      (Save ${(formData.subtotal - formData.total).toFixed(2)})
                    </Typography>
                  </Typography>
                </Box>
              ) : (
                <Typography variant="body1">
                  ${Number(formData.total).toFixed(2)}
                </Typography>
              )}
            </Box>
          </ListItem>
          {formData.preferredDays?.length > 0 && (
            <ListItem>
              <Typography variant="body1">
                <strong>Availability:</strong><br />
                Days: {formData.preferredDays.join(', ')}<br />
                Time: {formData.appointmentTime === 'morning' ? 'Mornings (9AM-12PM)' :
                       formData.appointmentTime === 'afternoon' ? 'Afternoons (1PM-4PM)' :
                       'Morning or Afternoon'}
              </Typography>
            </ListItem>
          )}
          <ListItem>
            <Typography variant="body1">
              <strong>Contact Information:</strong>
            </Typography>
          </ListItem>
          <ListItem sx={{ pl: 4 }}>
            <Box>
              <Typography variant="body1">{formData.name}</Typography>
              <Typography variant="body1">{formData.email}</Typography>
              <Typography variant="body1">{formData.phone}</Typography>
              <Typography variant="body1">{formData.zipCode}</Typography>
            </Box>
          </ListItem>
          {formData.message && (
            <ListItem>
              <Typography variant="body1">
                <strong>Additional Information:</strong><br />
                {formData.message}
              </Typography>
            </ListItem>
          )}
        </List>
      </Paper>
    </>
  );

  const renderContactSummary = () => (
    <>
      <Typography variant="h4" gutterBottom>
        Thank You for Contacting Us!
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        We've received your message and will get back to you as soon as possible.
      </Typography>

      <Paper elevation={1} sx={{ p: 3, backgroundColor: '#f8f8f8', textAlign: 'left' }}>
        <Typography variant="h6" gutterBottom color="primary">
          Message Details
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body1">
              <strong>Name:</strong> {formData.name}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              <strong>Email:</strong> {formData.email}
            </Typography>
          </ListItem>
          {formData.phone && (
            <ListItem>
              <Typography variant="body1">
                <strong>Phone:</strong> {formData.phone}
              </Typography>
            </ListItem>
          )}
          <ListItem>
            <Typography variant="body1">
              <strong>Message:</strong><br />
              {formData.message}
            </Typography>
          </ListItem>
        </List>
      </Paper>
    </>
  );

  return (
    <>
      <div style={{
        position: isScrolled ? 'fixed' : 'absolute',
        left: 0,
        right: 0,
        top: 0,
        zIndex: 1000,
        background: isScrolled ? 'rgba(255, 255, 255, 0.9)' : 'transparent',
        padding: '20px 30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backdropFilter: isScrolled ? 'blur(5px)' : 'none',
        boxShadow: isScrolled ? '0 2px 10px rgba(0,0,0,0.1)' : 'none',
        transition: 'all 0.3s ease',
        boxSizing: 'border-box'
      }}>
        <img
          src="/MWPlogo.png"
          alt="Marin Water Pros Logo"
          style={{ 
            height: isScrolled ? '60px' : '80px', 
            objectFit: 'contain', 
            filter: 'drop-shadow(0 2px 5px rgba(0,0,0,0.3))',
            transition: 'height 0.3s ease'
          }}
        />
        <Button
          variant="outlined"
          color="primary"
          startIcon={<HomeIcon />}
          href="/"
          sx={{
            borderRadius: '20px',
            textTransform: 'none',
            fontSize: '1rem',
            borderColor: '#1A4E7A',
            color: '#1A4E7A',
            '&:hover': {
              backgroundColor: 'rgba(26, 78, 122, 0.1)',
              borderColor: '#1A4E7A',
            },
          }}
        >
          Return Home
        </Button>
      </div>

      <Container maxWidth="lg" sx={{ 
        my: 4,
        mt: { xs: '120px', sm: '140px' }
      }}>
        <Box sx={{ 
          maxWidth: '800px', 
          margin: '40px auto',
          padding: { xs: 2, sm: 3 },
        }}>
          <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
            <CheckCircleOutlineIcon 
              sx={{ 
                fontSize: 64, 
                color: theme.palette.success.main,
                mb: 2
              }} 
            />
            
            {formType === 'contact' ? renderContactSummary() : renderQuoteSummary()}

            <Button 
              variant="contained" 
              href="/"
              sx={{ mt: 4 }}
            >
              Return to Home
            </Button>
          </Paper>
        </Box>
      </Container>
      <Footer />
    </>
  );
}

export default ThankYou; 