import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  Box, Typography, Container, Paper, Divider, Button, Stepper, Step, StepLabel, TextField, FormControl, FormLabel,
  RadioGroup, FormControlLabel, Radio, Select, MenuItem, InputLabel, List, ListItem, FormGroup, Checkbox,
  Grid, useTheme, useMediaQuery, Modal, Card, CardContent, CardActions, ListItemIcon, ListItemText, Chip, IconButton,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import '@fontsource/roboto/700.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/poppins/600.css';
import Footer from '../components/Footer';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Open Sans, sans-serif',
    h1: { fontFamily: 'Poppins, sans-serif', fontWeight: 700 },
    h2: { fontFamily: 'Poppins, sans-serif', fontWeight: 600 },
    h3: { fontFamily: 'Poppins, sans-serif', fontWeight: 600 },
    body1: { fontFamily: 'Open Sans, sans-serif' },
  },
  palette: {
    primary: { main: '#1A4E7A' },
    secondary: { main: '#F28C38' },
  },
});

const steps = ['Select Services', 'Basic Info', 'Property Details', 'Schedule', 'Contact Info'];

const packages = [
  { id: 1, name: 'Basic Filtration', price: '$115', features: ['Baseline filtration', 'Removes chlorine and sediment', 'Stainless Steel filter housings', 'In-line with existing faucet', 'Filter replacement alert system', 'Great addition to bathroom sinks'] },
  { id: 2, name: 'Essential Filtration', price: '$235', features: ['3 stage filtration', '99.99% of Contaminants Larger Than 0.01μm', 'No impact to existing water pressure', 'Removes chlorine and sediment', 'Reduces 95% of PFAS', 'Retains mineral content'] },
  { id: 3, name: 'Advanced Filtration', price: '$685', features: ['3-Stage, carbon filtration', 'Targets chlorine, fluoride, lead & PFAS', 'Removes up to 99.99% of 232+ contaminants', 'Available as in-line or dedicated faucet', 'Low maintenance cost with 1+ year filter life'], popular: true },
  { id: 4, name: 'Elite Filtration', price: '$885', features: ['State-of-the-Art Tankless Reverse Osmosis', '9-layer, 0.0001 filtration precision', 'Removes 1000+ harmful contaminants', 'Pressurized for instant flow RO', 'Dedicated faucet included', 'Remineralization system included'] },
];

const addons = [
  { 
    id: 1, 
    name: 'Before/After QA Lab Testing', 
    price: 240,
    description: 'Professional lab analysis of your water quality before and after installation',
    popular: true
  },
  { 
    id: 2, 
    name: 'UV Filtration', 
    price: 90,
    description: 'Additional protection against bacteria and viruses using UV light technology'
  },
  { 
    id: 3, 
    name: 'Remineralization Attachment', 
    price: 125,
    description: 'Adds beneficial minerals back into filtered water for optimal taste and health'
  },
  { 
    id: 4, 
    name: 'Bathroom Sink Filter', 
    price: 75,
    description: 'Extend filtration to your bathroom sink with an additional filter system'
  }
];

const faqData = [
  {
    question: "How long does installation take?",
    answer: "Most installations take 1-2 hours. Our expert technicians work efficiently while ensuring everything is properly set up."
  },
  {
    question: "Do I need to be home during installation?",
    answer: "Yes, we require someone to be home during the installation to provide access and approve the final setup."
  },
  {
    question: "What's included in the installation?",
    answer: "Installation includes mounting the filtration system, connecting to your water supply, testing the system, and a brief demonstration of how to use and maintain it."
  },
  {
    question: "How often do filters need to be replaced?",
    answer: "Filter replacement varies by system and usage. Generally, filters need replacement every 6-12 months. We'll set up alerts to remind you when it's time."
  }
];

function GetAQuote() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const canvasRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    zipCode: '', waterSource: '', selectedPackage: null, selectedAddons: [], propertyType: '',
    numberOfBathrooms: '', name: '', email: '', phone: '', preferredDays: [], appointmentTime: '', message: '',
  });
  const [promoCode, setPromoCode] = useState('');
  const [validPromoCode, setValidPromoCode] = useState(false);
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [showAddons, setShowAddons] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openFAQModal, setOpenFAQModal] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showMonthly, setShowMonthly] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = 250;
    };
    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    const particles = [];
    for (let i = 0; i < 20; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 3 + 2,
        dx: (Math.random() - 0.5) * 0.4,
        dy: (Math.random() - 0.5) * 0.4,
      });
    }

    const drawParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
      particles.forEach(particle => {
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
        ctx.fill();
        particle.x += particle.dx;
        particle.y += particle.dy;
        if (particle.x < 0 || particle.x > canvas.width) particle.dx = -particle.dx;
        if (particle.y < 0 || particle.y > canvas.height) particle.dy = -particle.dy;
      });
      animationFrameId = requestAnimationFrame(drawParticles);
    };
    drawParticles();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  useEffect(() => {
    const packageId = parseInt(searchParams.get('package'));
    if (packageId && packageId >= 1 && packageId <= 4) {
      setFormData(prev => ({ ...prev, selectedPackage: packageId }));
    }
  }, [searchParams]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const togglePackage = (packageId) => setFormData(prev => ({ ...prev, selectedPackage: packageId }));
  const toggleAddon = (addonId) => setFormData(prev => ({
    ...prev,
    selectedAddons: prev.selectedAddons.includes(addonId)
      ? prev.selectedAddons.filter(id => id !== addonId)
      : [...prev.selectedAddons, addonId],
  }));

  const calculateTotal = () => {
    const packagePrice = packages.find(pkg => pkg.id === formData.selectedPackage)?.price.replace('$', '') || 0;
    const addonsPrice = addons.filter(addon => formData.selectedAddons.includes(addon.id)).reduce((sum, addon) => sum + addon.price, 0);
    const subtotal = parseFloat(packagePrice) + addonsPrice;
    return validPromoCode ? subtotal * 0.95 : subtotal;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (activeStep === steps.length - 1) {
      const finalData = {
        ...formData,
        packageName: packages.find(pkg => pkg.id === formData.selectedPackage)?.name,
        addons: addons.filter(addon => formData.selectedAddons.includes(addon.id)).map(addon => addon.name).join(', '),
        subtotal: calculateTotal() / (validPromoCode ? 0.95 : 1),
        total: calculateTotal(),
        promoApplied: validPromoCode ? 'Yes (5% off)' : 'No',
      };
      try {
        await fetch('/', {
          method: 'POST',
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({ 'form-name': 'quote-request', ...finalData }).toString(),
        });
        navigate('/thank-you', { state: { formData: finalData, formType: 'quote' } });
      } catch (error) {
        console.error('Form submission error:', error);
        alert('Error submitting your quote request. Please try again.');
      }
    } else {
      setActiveStep(prev => prev + 1);
    }
  };

  const handleBack = () => setActiveStep(prev => prev - 1);
  const handlePromoCodeChange = (event) => setPromoCode(event.target.value);
  const applyPromoCode = () => {
    if (promoCode.toUpperCase() === 'MWP5') {
      setValidPromoCode(true);
      alert('Promo code applied! 5% discount added.');
    } else {
      setValidPromoCode(false);
      alert('Invalid promo code.');
    }
    setPromoCode('');
  };

  const handleChoosePlan = (packageId) => {
    setFormData(prev => ({ ...prev, selectedPackage: packageId }));
    setOpenModal(false);
  };

  const MobileStepper = () => (
    <Box sx={{ mb: 4, textAlign: 'center' }}>
      <Stepper activeStep={activeStep} alternativeLabel sx={{ '& .MuiStepLabel-label': { display: { xs: 'none', sm: 'block' } } }}>
        {steps.map((label) => (
          <Step key={label}><StepLabel>{label}</StepLabel></Step>
        ))}
      </Stepper>
      <Typography variant="body2" sx={{ mt: 1, color: '#1A4E7A', fontWeight: 500 }}>
        Step {activeStep + 1} of {steps.length}: {steps[activeStep]}
      </Typography>
    </Box>
  );

  const renderSummary = () => {
    const subtotal = calculateTotal() / (validPromoCode ? 0.95 : 1);
    const savings = validPromoCode ? subtotal * 0.05 : 0;
    const monthlyAmount = Math.round(calculateTotal() / 12);

    return (
      <Box sx={{ p: 2, backgroundColor: '#fff', borderRadius: 2, boxShadow: '0 5px 15px rgba(0,0,0,0.1)' }}>
        <Typography variant="h6" gutterBottom color="primary">Your Quote Summary</Typography>
        <List>
          {formData.selectedPackage && <ListItem><Typography color="#1A4E7A">Package: {packages.find(pkg => pkg.id === formData.selectedPackage).name}</Typography></ListItem>}
          {formData.selectedAddons.length > 0 && <ListItem><Typography color="#1A4E7A">Add-ons: {formData.selectedAddons.map(id => addons.find(addon => addon.id === id).name).join(', ')}</Typography></ListItem>}
          {formData.zipCode && <ListItem><Typography color="#1A4E7A">Zip Code: {formData.zipCode}</Typography></ListItem>}
          {formData.waterSource && <ListItem><Typography color="#1A4E7A">Water Source: {formData.waterSource}</Typography></ListItem>}
          {formData.propertyType && <ListItem><Typography color="#1A4E7A">Property Type: {formData.propertyType}</Typography></ListItem>}
          {formData.numberOfBathrooms && <ListItem><Typography color="#1A4E7A">Bathrooms: {formData.numberOfBathrooms}</Typography></ListItem>}
          {formData.preferredDays?.length > 0 && formData.appointmentTime && (
            <ListItem>
              <Typography color="#1A4E7A">
                <strong>Availability:</strong> {formData.preferredDays.join(', ')}, 
                {formData.appointmentTime === 'morning' ? ' Mornings (9AM-12PM)' :
                 formData.appointmentTime === 'afternoon' ? ' Afternoons (1PM-4PM)' :
                 ' Morning or Afternoon'}
              </Typography>
            </ListItem>
          )}
        </List>
        <Box sx={{ mt: 2, p: 2, backgroundColor: '#f4f7fa', borderRadius: 1 }}>
          <Typography variant="h6" gutterBottom color="primary">Payment Options</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Button
              size="small"
              variant={!showMonthly ? "contained" : "outlined"}
              onClick={() => setShowMonthly(false)}
              sx={{ 
                mr: 1,
                backgroundColor: !showMonthly ? '#1A4E7A' : 'transparent',
                '&:hover': { backgroundColor: !showMonthly ? '#2A6A8F' : 'rgba(26, 78, 122, 0.1)' }
              }}
            >
              One-time Payment
            </Button>
            <Button
              size="small"
              variant={showMonthly ? "contained" : "outlined"}
              onClick={() => setShowMonthly(true)}
              sx={{ 
                backgroundColor: showMonthly ? '#1A4E7A' : 'transparent',
                '&:hover': { backgroundColor: showMonthly ? '#2A6A8F' : 'rgba(26, 78, 122, 0.1)' }
              }}
            >
              Monthly Payments
            </Button>
          </Box>
          {validPromoCode && (
            <>
              <Typography variant="body2" color="text.secondary" sx={{ textDecoration: 'line-through' }}>${subtotal.toFixed(2)}</Typography>
              <Typography variant="body2" color="success.main" gutterBottom>5% off (${savings.toFixed(2)})</Typography>
            </>
          )}
          <Typography variant="h5" color="text.primary" align="center">
            {showMonthly ? (
              <>
                ${monthlyAmount}/month
                <Typography variant="body2" color="text.secondary">
                  for 12 months (total ${calculateTotal().toFixed(2)})
                </Typography>
              </>
            ) : (
              `$${calculateTotal().toFixed(2)}`
            )}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderPromoCode = () => (
    <Box sx={{ mt: 2, p: 2, backgroundColor: '#fff', borderRadius: 2, boxShadow: '0 5px 15px rgba(0,0,0,0.1)' }}>
      <Typography variant="h6" gutterBottom color="primary">Promo Code</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField fullWidth size="small" label="Enter promo code" value={promoCode} onChange={handlePromoCodeChange} sx={{ mr: 1, backgroundColor: '#fff' }} />
        <Button variant="contained" onClick={applyPromoCode} sx={{ backgroundColor: '#F28C38', '&:hover': { backgroundColor: '#E07B30' } }}>Apply</Button>
      </Box>
    </Box>
  );

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ py: 2, animation: 'fadeInUp 0.5s ease-out' }}>
            <Typography variant="h6" gutterBottom sx={{ mb: 3, color: '#1A4E7A' }}>Select Your Filtration Package</Typography>
            <Box sx={{ 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 3,
              p: 2,
              backgroundColor: 'rgba(26, 78, 122, 0.05)',
              borderRadius: 2,
              border: '1px dashed #1A4E7A'
            }}>
              <HelpOutlineIcon sx={{ mr: 1, color: '#1A4E7A' }} />
              <Typography variant="body1" sx={{ mr: 1, color: '#1A4E7A' }}>
                Not sure which package is right for you?
              </Typography>
              <Button
                variant="contained"
                onClick={() => setOpenModal(true)}
                sx={{
                  backgroundColor: '#F28C38',
                  '&:hover': { backgroundColor: '#E07B30' },
                  textTransform: 'none',
                  boxShadow: '0 2px 8px rgba(242, 140, 56, 0.3)',
                  fontSize: '1rem'
                }}
              >
                Compare All Packages
              </Button>
            </Box>
            <Grid container spacing={2}>
              {packages.map((pkg) => (
                <Grid item xs={12} sm={6} key={pkg.id}>
                  <Box sx={{ position: 'relative' }}>
                    {pkg.popular && (
                      <Chip
                        label="Most Popular"
                        color="secondary"
                        size="small"
                        sx={{
                          position: 'absolute',
                          top: -10,
                          right: 10,
                          zIndex: 1,
                          backgroundColor: '#F28C38',
                          color: '#fff',
                          fontWeight: 600
                        }}
                      />
                    )}
                    <Button
                      fullWidth
                      variant="outlined"
                      sx={{
                        p: 2,
                        borderColor: formData.selectedPackage === pkg.id ? '#1A4E7A' : '#ccc',
                        backgroundColor: formData.selectedPackage === pkg.id ? '#1A4E7A' : 'transparent',
                        color: formData.selectedPackage === pkg.id ? '#fff' : '#333',
                        '&:hover': { backgroundColor: formData.selectedPackage === pkg.id ? '#2A6A8F' : '#f4f7fa' },
                        textAlign: 'left',
                        borderRadius: 2,
                        transition: 'all 0.2s ease',
                        boxShadow: formData.selectedPackage === pkg.id ? '0 4px 12px rgba(26, 78, 122, 0.2)' : 'none',
                        '& .MuiTypography-root': {
                          transition: 'all 0.2s ease'
                        },
                        '& .package-description': {
                          fontSize: '0.85rem',
                          color: formData.selectedPackage === pkg.id ? 'rgba(255,255,255,0.8)' : '#666',
                          mt: 1
                        }
                      }}
                      onClick={() => togglePackage(pkg.id)}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ color: 'inherit', fontWeight: 600 }}>{pkg.name}</Typography>
                        <Typography sx={{ mt: 1, color: formData.selectedPackage === pkg.id ? '#fff' : '#F28C38' }}>
                          {showMonthly ? `$${Math.round(parseInt(pkg.price.replace('$','')) / 12)}/mo` : pkg.price}
                          {showMonthly && (
                            <Typography component="span" sx={{ fontSize: '0.8rem', ml: 1, opacity: 0.8 }}>
                              (12 months)
                            </Typography>
                          )}
                        </Typography>
                        <Typography className="package-description">
                          {pkg.id === 1 ? 'Perfect for single faucet filtration' :
                           pkg.id === 2 ? 'Ideal for small households' :
                           pkg.id === 3 ? 'Most popular for families' :
                           'Ultimate whole-house solution'}
                        </Typography>
                      </Box>
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Typography variant="h6" gutterBottom sx={{ mt: 4, mb: 3, color: '#1A4E7A' }}>Add-ons (Optional)</Typography>
            <Grid container spacing={2}>
              {addons.map((addon) => (
                <Grid item xs={12} sm={6} key={addon.id}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      border: '1px solid',
                      borderColor: formData.selectedAddons.includes(addon.id) ? '#1A4E7A' : '#e0e0e0',
                      borderRadius: 2,
                      position: 'relative',
                      backgroundColor: formData.selectedAddons.includes(addon.id) ? 'rgba(26, 78, 122, 0.05)' : 'transparent',
                      transition: 'all 0.2s ease',
                      cursor: 'pointer',
                      '&:hover': {
                        borderColor: '#1A4E7A',
                        backgroundColor: 'rgba(26, 78, 122, 0.02)'
                      }
                    }}
                    onClick={() => toggleAddon(addon.id)}
                  >
                    {addon.popular && (
                      <Chip
                        label="Popular Add-on"
                        size="small"
                        sx={{
                          position: 'absolute',
                          top: -10,
                          right: 10,
                          backgroundColor: '#F28C38',
                          color: '#fff',
                          fontSize: '0.75rem'
                        }}
                      />
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                      <Checkbox
                        checked={formData.selectedAddons.includes(addon.id)}
                        sx={{ 
                          p: 0,
                          mr: 1,
                          color: '#1A4E7A',
                          '&.Mui-checked': {
                            color: '#1A4E7A'
                          }
                        }}
                      />
                      <Box>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#1A4E7A' }}>
                          {addon.name}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#666', mb: 1 }}>
                          {addon.description}
                        </Typography>
                        <Typography 
                          variant="subtitle1" 
                          sx={{ 
                            color: '#F28C38',
                            fontWeight: 600
                          }}
                        >
                          ${addon.price}
                          {showMonthly && (
                            <Typography component="span" sx={{ fontSize: '0.8rem', ml: 1, color: '#666' }}>
                              (${Math.round(addon.price / 12)}/mo)
                            </Typography>
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        );
      case 1:
        return (
          <Box sx={{ py: 2, animation: 'fadeInUp 0.5s ease-out' }}>
            <TextField fullWidth label="Zip Code" name="zipCode" value={formData.zipCode} onChange={handleChange} margin="normal" required sx={{ backgroundColor: '#fff' }} />
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Water Source</FormLabel>
              <RadioGroup name="waterSource" value={formData.waterSource} onChange={handleChange}>
                <FormControlLabel value="city" control={<Radio />} label="City Water" />
                <FormControlLabel value="well" control={<Radio />} label="Well Water" />
              </RadioGroup>
            </FormControl>
          </Box>
        );
      case 2:
        return (
          <Box sx={{ py: 2, animation: 'fadeInUp 0.5s ease-out' }}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Property Type</InputLabel>
              <Select name="propertyType" value={formData.propertyType} onChange={handleChange} sx={{ backgroundColor: '#fff' }}>
                <MenuItem value="house">House</MenuItem>
                <MenuItem value="apartment">Apartment</MenuItem>
                <MenuItem value="business">Business</MenuItem>
              </Select>
            </FormControl>
            <TextField fullWidth label="Number of Bathrooms" name="numberOfBathrooms" type="number" value={formData.numberOfBathrooms} onChange={handleChange} margin="normal" sx={{ backgroundColor: '#fff' }} />
          </Box>
        );
      case 3:
        return (
          <Box sx={{ py: 2, animation: 'fadeInUp 0.5s ease-out' }}>
            <Typography variant="h6" gutterBottom sx={{ color: '#1A4E7A' }}>
              Availability Preferences
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Select the days that generally work best for you. We'll schedule your specific installation date via email.
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              <Box component="span" fontWeight="bold">Typical Lead Time:</Box> 5-7 business days from quote approval
            </Typography>

            <FormControl component="fieldset" fullWidth margin="normal">
              <FormLabel component="legend" sx={{ mb: 2, color: '#1A4E7A' }}>Preferred Days</FormLabel>
              <FormGroup>
                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map(day => (
                  <FormControlLabel
                    key={day}
                    control={
                      <Checkbox
                        checked={formData.preferredDays?.includes(day) || false}
                        onChange={(e) => {
                          const days = formData.preferredDays || [];
                          setFormData(prev => ({
                            ...prev,
                            preferredDays: e.target.checked
                              ? [...days, day]
                              : days.filter(d => d !== day)
                          }));
                        }}
                        sx={{ 
                          color: '#1A4E7A',
                          '&.Mui-checked': {
                            color: '#1A4E7A'
                          }
                        }}
                      />
                    }
                    label={day}
                  />
                ))}
              </FormGroup>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>Preferred Time of Day</InputLabel>
              <Select
                name="appointmentTime"
                value={formData.appointmentTime}
                onChange={handleChange}
                sx={{ backgroundColor: '#fff' }}
              >
                <MenuItem value="morning">Morning (9:00 AM - 12:00 PM)</MenuItem>
                <MenuItem value="afternoon">Afternoon (1:00 PM - 4:00 PM)</MenuItem>
                <MenuItem value="either">Either Morning or Afternoon</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="body2" sx={{ mt: 2, color: '#666', fontStyle: 'italic' }}>
              * Final installation time will be confirmed via email after quote approval
            </Typography>
          </Box>
        );
      case 4:
        return (
          <Box sx={{ py: 2, animation: 'fadeInUp 0.5s ease-out' }}>
            <TextField fullWidth label="Name" name="name" value={formData.name} onChange={handleChange} margin="normal" required sx={{ backgroundColor: '#fff' }} />
            <TextField fullWidth label="Email" name="email" type="email" value={formData.email} onChange={handleChange} margin="normal" required sx={{ backgroundColor: '#fff' }} />
            <TextField fullWidth label="Phone" name="phone" value={formData.phone} onChange={handleChange} margin="normal" required sx={{ backgroundColor: '#fff' }} />
            <TextField
              fullWidth
              label="Additional Info"
              name="message"
              value={formData.message}
              onChange={handleChange}
              margin="normal"
              multiline
              rows={4}
              placeholder="Any specific concerns or requirements?"
              sx={{ backgroundColor: '#fff' }}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ minHeight: '100vh', background: 'rgba(244, 247, 250, 0.85)' }}>
        {/* Update Header */}
        <div style={{
          position: isScrolled ? 'fixed' : 'absolute',
          left: 0,
          right: 0,
          top: 0,
          zIndex: 1000,
          background: isScrolled ? 'rgba(244, 247, 250, 0.7)' : 'transparent',
          padding: '20px 30px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backdropFilter: isScrolled ? 'blur(5px)' : 'none',
          boxShadow: isScrolled ? '0 2px 10px rgba(0,0,0,0.1)' : 'none',
          transition: 'all 0.3s ease',
          boxSizing: 'border-box'
        }}>
          <a href="/" style={{ textDecoration: 'none' }}>
            <img 
              src="/MWPlogo.png" 
              alt="Marin Water Pros Logo" 
              style={{ 
                height: isScrolled ? '60px' : '80px', 
                filter: 'drop-shadow(0 2px 5px rgba(0,0,0,0.3))',
                cursor: 'pointer',
                transition: 'height 0.3s ease'
              }} 
            />
          </a>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<HelpOutlineIcon />}
            onClick={() => setOpenFAQModal(true)}
            sx={{
              borderRadius: '20px',
              textTransform: 'none',
              fontSize: '1rem',
              borderColor: '#1A4E7A',
              color: '#1A4E7A',
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                borderColor: '#1A4E7A',
              },
            }}
          >
            FAQ
          </Button>
        </div>

        {/* Update Hero section padding */}
        <div style={{ 
          position: 'relative', 
          background: 'linear-gradient(135deg, #1A4E7A 0%, #2A6A8F 100%)', 
          padding: isScrolled ? '160px 20px 60px' : '120px 20px 60px',
          overflow: 'hidden',
          transition: 'padding 0.3s ease'
        }}>
          <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', mixBlendMode: 'overlay' }} />
          <Container maxWidth="lg" style={{ position: 'relative', textAlign: 'center', color: '#fff' }}>
            <Typography variant="h2" gutterBottom sx={{ 
              fontWeight: 700, 
              fontSize: 'clamp(2rem, 4vw, 3rem)', 
              textShadow: '0 2px 10px rgba(0,0,0,0.3)',
              animation: 'fadeInUp 1s ease-out'
            }}>
              Time to Get Filtered
            </Typography>
            <Typography variant="h5" sx={{ 
              mb: 3, 
              fontSize: 'clamp(1rem, 2vw, 1.5rem)', 
              opacity: 0.9,
              animation: 'fadeInUp 1s ease-out 0.2s',
              animationFillMode: 'both'
            }}>
              Receive a personalized quote for your water filtration needs
            </Typography>
            <Divider sx={{ mb: 3, backgroundColor: 'rgba(255,255,255,0.5)' }} />
            <Typography variant="body1" sx={{ maxWidth: '800px', margin: '0 auto', fontSize: '1.1rem' }}>
              Fill out our simple form to get a tailored quote. Our experts will craft the perfect solution for you.
            </Typography>
          </Container>
        </div>

        <Container maxWidth="lg" style={{ padding: '60px 20px' }}>
          <Paper sx={{ p: 4, borderRadius: 3, background: 'linear-gradient(45deg, #1A4E7A, #2A6A8F)', color: '#fff', mb: 6 }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>Get Your Personalized Quote</Typography>
            {isMobile && <MobileStepper />}
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Paper sx={{ p: 4, backgroundColor: '#fff', borderRadius: 2, boxShadow: '0 5px 20px rgba(0,0,0,0.1)' }}>
                  <form onSubmit={handleSubmit} name="quote-request" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                    <input type="hidden" name="form-name" value="quote-request" />
                    <p hidden><label>Don't fill this out: <input name="bot-field" /></label></p>
                    {!isMobile && <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>{steps.map(label => <Step key={label}><StepLabel>{label}</StepLabel></Step>)}</Stepper>}
                    {renderStepContent(activeStep)}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                      <Button disabled={activeStep === 0} onClick={handleBack} sx={{ color: '#1A4E7A' }}>Back</Button>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{ backgroundColor: '#F28C38', '&:hover': { backgroundColor: '#E07B30' } }}
                        disabled={activeStep === 0 && !formData.selectedPackage}
                      >
                        {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                      </Button>
                    </Box>
                  </form>
                </Paper>
              </Grid>
              {!isMobile && (
                <Grid item xs={12} md={4}>
                  {renderSummary()}
                  {renderPromoCode()}
                </Grid>
              )}
            </Grid>
          </Paper>
        </Container>

        {/* Modal for Package Details */}
        <Modal open={openModal} onClose={() => setOpenModal(false)} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ 
            width: '80vw', 
            maxHeight: '80vh', 
            backgroundColor: '#fff', 
            borderRadius: 3, 
            p: 3, 
            boxShadow: '0 10px 30px rgba(0,0,0,0.2)', 
            position: 'relative',
            overflowY: 'auto',
          }}>
            <IconButton onClick={() => setOpenModal(false)} sx={{ position: 'absolute', top: 8, right: 8, color: '#1A4E7A' }}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" gutterBottom sx={{ color: '#1A4E7A', fontWeight: 600, textAlign: 'center', fontSize: '1.75rem' }}>Filtration Packages</Typography>
            <Grid container spacing={2} sx={{ overflowX: { xs: 'auto', md: 'hidden' }, flexWrap: { xs: 'nowrap', md: 'wrap' }, pb: 2 }}>
              {packages.map((pkg) => (
                <Grid item xs={12} sm={6} md={3} key={pkg.id}>
                  <Card sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    boxShadow: '0 5px 15px rgba(0,0,0,0.1)', 
                    position: 'relative',
                    minWidth: { xs: 280, md: 'auto' }, // Ensures scrollable width on mobile
                  }}>
                    {pkg.popular && (
                      <Chip
                        icon={<StarIcon />}
                        label="Most Popular"
                        color="secondary"
                        sx={{ position: 'absolute', top: 10, right: 10, zIndex: 1, fontSize: '0.8rem' }}
                      />
                    )}
                    <CardContent sx={{ flexGrow: 1, pt: pkg.popular ? 5 : 2 }}>
                      <Typography gutterBottom variant="h6" component="h2" align="center" sx={{ color: '#1A4E7A', fontSize: '1.2rem' }}>{pkg.name}</Typography>
                      <Typography variant="h5" component="p" align="center" sx={{ my: 1, color: '#F28C38', fontSize: '1.5rem' }}>{pkg.price}</Typography>
                      <Typography variant="body2" align="center" sx={{ mb: 2, fontStyle: 'italic', color: '#555', fontSize: '0.9rem' }}>
                        {pkg.id === 1 ? 'Affordable clean water for the essentials' : 
                         pkg.id === 2 ? 'Solid protection for everyday needs' : 
                         pkg.id === 3 ? 'Pro-level RO at a practical price' : 
                         'The ultimate pure water solution'}
                      </Typography>
                      <List dense>
                        {pkg.features.map((feature, idx) => (
                          <ListItem key={idx} disablePadding>
                            <ListItemIcon><CheckCircleOutlineIcon color="primary" sx={{ fontSize: '1rem' }} /></ListItemIcon>
                            <ListItemText primary={feature} sx={{ '& .MuiTypography-root': { fontSize: '0.9rem' } }} />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'center', pb: 2 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleChoosePlan(pkg.id)}
                        sx={{ backgroundColor: '#F28C38', '&:hover': { backgroundColor: '#E07B30' }, fontSize: '0.9rem', padding: '6px 16px' }}
                      >
                        Choose Plan
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Modal>

        {/* FAQ Modal */}
        <Modal
          open={openFAQModal}
          onClose={() => setOpenFAQModal(false)}
          aria-labelledby="faq-modal-title"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
          }}
        >
          <Paper
            sx={{
              maxWidth: 600,
              maxHeight: '90vh',
              width: '100%',
              overflow: 'auto',
              p: 3,
              position: 'relative',
              borderRadius: 2,
            }}
          >
            <IconButton
              onClick={() => setOpenFAQModal(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'grey.500',
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#1A4E7A', mb: 3, pr: 4 }}>
              Frequently Asked Questions
            </Typography>
            {faqData.map((faq, index) => (
              <Box key={index} sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom sx={{ color: '#1A4E7A' }}>
                  {faq.question}
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                  {faq.answer}
                </Typography>
              </Box>
            ))}
          </Paper>
        </Modal>

        {/* Replace the existing footer with the new Footer component */}
        <Footer />
      </div>

      <style>{`
        @keyframes fadeInUp {
          from { opacity: 0; transform: translateY(20px); }
          to { opacity: 1; transform: translateY(0); }
        }
        @media (max-width: 768px) {
          .MuiTabs-root { flex-direction: 'column'; }
          .MuiTab-root { width: 100%; }
        }
      `}</style>
    </ThemeProvider>
  );
}

export default GetAQuote;